


export function reroute({ url }) {
  if (url.pathname == "/signup") {
    return "/login"
  }

  if (url.pathname == "/privacy-policy.html") {
    return "/about/privacy-policy"
  }

  if (url.pathname == "/terms-of-service.html") {
    return "/about/terms-of-use"
  }
}
