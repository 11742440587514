import * as client_hooks from '../../../src/hooks.client.js';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87')
];

export const server_loads = [0];

export const dictionary = {
		"/": [11],
		"/(app)/@[slug]": [37,[2]],
		"/about/beta": [73],
		"/about/founder-story": [74],
		"/about/manifesto": [75],
		"/about/[slug]": [72],
		"/app-home": [76],
		"/app-home/vibes": [77],
		"/(app)/assets/g/lists/og1/[id]": [38,[6,7]],
		"/(app)/assets/g/trust-score/og1/[id]": [39,[6,8]],
		"/(app)/assets/g/u/[slug]/stack/og1": [40,[6,9]],
		"/(app)/brands": [41,[2]],
		"/(app)/brands/[slug]": [42,[2]],
		"/(app)/brands/[slug]/trustscore": [43,[2]],
		"/(app)/collective": [44,[2]],
		"/contact": [78],
		"/delete-account": [79],
		"/(app)/discover": [45,[2]],
		"/(app)/download": [46,[2]],
		"/(app)/(private)/feedback": [12,[2,3]],
		"/get-started": [80],
		"/get-started/find-trusted-products": [81],
		"/get-started/get-recommendations": [82],
		"/get-started/get-reminders": [83],
		"/get-started/optimize-my-stack": [84],
		"/get-started/save-money": [85],
		"/(app)/(private)/home": [13,[2,3]],
		"/(app)/(private)/internal/chat": [14,[2,3]],
		"/(app)/lists/[id]": [47,[2]],
		"/lp/[slug]": [86],
		"/(app)/(private)/mocks/auth/signin": [15,[4]],
		"/(app)/(private)/my/analyses/suppscore": [16,[2,3]],
		"/(app)/(private)/my/analyses/suppscore/complete": [17,[2,3]],
		"/(app)/(private)/my/analyses/suppscore/wizard": [18,[2,3]],
		"/(app)/(private)/my/analyses/suppscore/wizard/[slide]": [19,[2,3]],
		"/(app)/(private)/my/cabinet": [20,[2,3]],
		"/(app)/(private)/my/lists": [21,[2,3]],
		"/(app)/(private)/my/lists/[id]": [22,[2,3]],
		"/(app)/(private)/my/lists/[id]/edit": [23,[2,3]],
		"/(app)/(private)/my/protocols": [24,[2,3]],
		"/(app)/(private)/my/protocols/recommended": [25,[2,3]],
		"/(app)/(private)/my/schedule/[[date]]": [26,[2,3]],
		"/(app)/(private)/my/stack/nutrients": [27,[2,3,5]],
		"/(app)/(private)/my/stack/products": [28,[2,3,5]],
		"/(app)/(private)/my/stack/protocols": [29,[2,3,5]],
		"/(app)/nutrients": [48,[2]],
		"/(app)/nutrients/popular": [50,[2]],
		"/(app)/nutrients/[slug]": [49,[2]],
		"/(app)/products": [51,[2]],
		"/(app)/(private)/products/edit": [30,[2,3]],
		"/(app)/products/nutrients": [53,[2]],
		"/(app)/products/nutrients/[slug]": [54,[2]],
		"/(app)/products/popular": [55,[2]],
		"/(app)/(private)/products/submit/[[id]]": [31,[2,3]],
		"/(app)/products/[slug]": [52,[2]],
		"/(app)/profiles/popular": [58,[2]],
		"/(app)/profiles/[slug]": [56,[2]],
		"/(app)/profiles/[slug]/collections/quick-details": [57,[2]],
		"/(app)/(private)/profile": [32,[2,3]],
		"/(app)/(private)/profile/logs": [~33,[2,3]],
		"/(app)/protocols": [59,[2]],
		"/(app)/protocols/[slug]": [60,[2]],
		"/(app)/protocols/[slug]/match-confirm": [61,[2]],
		"/(app)/protocols/[slug]/select-products": [62,[2]],
		"/(app)/(private)/saves": [34,[2,3]],
		"/sentry/example": [87],
		"/(app)/(private)/settings": [35,[2,3]],
		"/(app)/(private)/settings/profile": [36,[2,3]],
		"/(app)/stacks/users/[slug]": [63,[2,10]],
		"/(app)/stacks/users/[slug]/nutrients": [64,[2,10]],
		"/(app)/stacks/users/[slug]/products": [65,[2,10]],
		"/(app)/stacks/users/[slug]/protocols": [66,[2,10]],
		"/(app)/supplements": [67,[2]],
		"/(app)/supplements/popular": [69,[2]],
		"/(app)/supplements/[slug]": [68,[2]],
		"/(app)/trustscore": [70,[2]],
		"/(app)/waitlist": [71,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.js';